import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import ConfirmEmail from "../views/Pages/Authentication/ConfirmEmail";
import store from "@/store/store.js";

// Test
const TestMention = () => import("../views/case/TestMention.vue");

// Sites
const Sites = () => import("../views/site/Site.vue");
const SiteDetail = () => import("../views/SiteDetail");
const OnlineSites = () => import("../views/admin/OnlineSites.vue");
const MissingEstimate = () => import("../views/admin/MissingEstimate.vue");
const MissingKwh = () => import("../views/admin/MissingKwh.vue");
// const NewSiteMetrics = () => import("../views/SiteDetail/NewSiteMetrics.vue")
//User Profile
const Profile = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");

//Reports
// const IndividualReports = () => import("../views/reports/IndividualReports.vue");
// const ManySites = () => import("../views/reports/ManySites.vue");
const SingleCustomer = () => import("../views/reports/SingleCustomer.vue");
const FleetView = () => import("../views/reports/FleetView.vue");
const CreateGroup = () => import("../views/reports/CreateGroup.vue");
const ReportAdmin = () => import("../views/reports/ReportAdmin.vue");
// const QuarterChart = () => import("../views/reports/QuarterChart.vue");

//Inverters
const Inverter = () => import("../views/inverter/Inverter.vue");

//Cases
const OpenCases = () => import("../views/case/OpenCases.vue");
const ClosedCases = () => import("../views/case/ClosedCases.vue");
const Case = () => import('../views/case/Case.vue');

//Profiles
const UserManagement = () => import("../views/admin/user/UserManagment.vue")
const Customer = () => import("../views/customer/Customer.vue");
const Account = () => import("../views/account/Account.vue");
const Contact = () => import("../views/contact/Contact.vue");

//Admin
const APIManagement = () => import("../views/APIManagement.vue");
const DataManagement = () => import("../views/DataManagement.vue");
const SiteManagement = () => import("../views/SiteManagement.vue");
const ImportIssue = () => import("../views/ImportIssue.vue");
const AdminLog = () => import("../views/AdminLog.vue");
const AdminSetting = () => import("../views/AdminSetting.vue");
const CSVUpload = () => import("../views/CSVUpload.vue");


// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const Analytics = () => import("../views/Dashboard/Analytics.vue");
const Discover = () => import("../views/Dashboard/Discover.vue");
const Automotive = () => import("../views/Dashboard/Automotive.vue");
const Sales = () => import("../views/Dashboard/Sales.vue");
const SmartHome = () => import("../views/Dashboard/SmartHome.vue");
const VrDefault = () => import("../views/Dashboard/VrDefault.vue");
const VrInfo = () => import("../views/Dashboard/VrInfo.vue");
const Crm = () => import("../views/Dashboard/Crm.vue");
const GettingStarted = () => import("../views/Dashboard/GettingStarted.vue");

// Login
const SignUpCover = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Cover.vue"
  );
const SignIn = () =>
  import("../views/Pages/Authentication/SignIn")

// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Rtl = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Rtl.vue");
const ProfileOverview = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileOverview.vue"
  );
const Messages = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Messages.vue");
const Projects = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Projects.vue");
const Reports = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Billing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Invoice = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Invoice.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");
const SignUpBasic = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Basic.vue"
  );
const SignUpIllustration = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Illustration.vue"
  );

// Applications
const Kanban = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Kanban.vue");
const Wizard = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Wizard.vue");
const Datatables = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");
const Calendar = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

// Ecommerce
const NewProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/NewProduct.vue"
  );
const EditProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/EditProduct.vue"
  );
const ProductPage = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/ProductPage.vue"
  );
const OrderList = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderList.vue"
  );
const OrderDetails = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderDetails.vue"
  );

Vue.use(VueRouter);

// let vrPages = {
//   path: "/",
//   component: DashboardLayoutVr,
//   name: "Vr",
//   children: [
//     {
//       path: "/pages/dashboards/vr/vr-default",
//       name: "VrDefault",
//       component: VrDefault,
//       meta: {
//         groupName: "Dashboards",
//       },
//     },
//     {
//       path: "/pages/dashboards/vr/vr-info",
//       name: "VrInfo",
//       component: VrInfo,
//       meta: {
//         groupName: "Dashboards",
//       },
//     },
//   ],
// };

// let userPages = {
//   path: "/",
//   component: DashboardLayout,
//   name: "Users",
//   children: [
//     {
//       path: "/pages/pages/users/reports",
//       name: "Reports",
//       component: Reports,
//       meta: {
//         groupName: "Pages",
//       },
//     },
//     {
//       path: "/pages/pages/users/new-user",
//       name: "NewUser",
//       component: NewUser,
//       meta: {
//         groupName: "Pages",
//       },
//     },
//   ],
// };

// let accountPages = {
//   path: "/",
//   component: DashboardLayout,
//   name: "Account",
//   children: [
//     {
//       path: "/pages/pages/account/settings",
//       name: "Settings",
//       component: Settings,
//       meta: {
//         groupName: "Pages",
//       },
//     },
//     {
//       path: "/pages/pages/account/billing",
//       name: "Billing",
//       component: Billing,
//       meta: {
//         groupName: "Pages",
//       },
//     },
//     {
//       path: "/pages/pages/account/invoice",
//       name: "Invoice",
//       component: Invoice,
//       meta: {
//         groupName: "Pages",
//       },
//     },
//   ],
// };

// let projectsPages = {
//   path: "/",
//   component: DashboardLayout,
//   name: "Projects",
//   children: [
//     {
//       path: "/pages/pages/projects/timeline",
//       name: "Timeline",
//       component: Timeline,
//       meta: {
//         groupName: "Pages",
//       },
//     },
//   ],
// };

// let pricingPage = {
//   path: "/",
//   component: PageLayout,
//   name: "Pricing Page",
//   children: [
//     {
//       path: "/pages/pages/pricing-page",
//       name: "Pricing",
//       component: Pricing,
//     },
//   ],
// };

// let authBasicPages = {
//   path: "/",
//   component: AuthBasicLayout,
//   name: "Authentication Basic",
//   children: [
//     {
//       path: "/pages/authentication/signup/basic",
//       name: "SignUpBasic",
//       component: SignUpBasic,
//     },
//   ],
// };

let signInPage = {
  path: "/",
  component: AuthCoverLayout,
  name: "Authentication Cover",
  children: [
    {
      path: "sign-in",
      name: "Sign In",
      component: SignIn,
    }
  ],
};

// let authIllustrationPages = {
//   path: "/",
//   component: AuthIllustrationLayout,
//   name: "Authentication Illustration",
//   children: [
//     {
//       path: "/pages/authentication/signup/illustration",
//       name: "SignUpIllustration",
//       component: SignUpIllustration,
//     },
//   ],
// };
let reportPage = {
  path: "/",
  component: DashboardLayout,
  name: "Reports",
  children: [
    // {
    //   path: "/reports/individual-reports",
    //   name: "IndividualReports",
    //   component: IndividualReports,
    //   meta: {
    //     groupName: "Reports"
    //   },
    // },
    // {
    //   path: "/reports/many-sites",
    //   name: "ManySites",
    //   component: ManySites,
    //   meta: {
    //     groupName: "Reports"
    //   },
    // },
    {
      path: "/reports/report",
      name: "ReportAdmin",
      component: ReportAdmin,
      meta: {
        groupName: "Reports"
      },
    },
    {
      path: "/reports/create-group",
      name: "CreateGroup",
      component: CreateGroup,
      meta: {
        groupName: "Reports"
      },
    },
  ]
}
let sitePage = {
  path: "/",
  component: DashboardLayout,
  name: "Site",
  children: [
    {
      path: "sites",
      name: "Sites",
      component: Sites,
      meta: {
        groupName: "YourSites"
      },
    },
    {
      path: '/sites/:id',
      name: 'SiteDetail',
      component: SiteDetail,
      meta: {
        groupName: "YourSites"
      }
    },
    {
      path: '/onlinesites',
      name: 'OnlineSites',
      component: OnlineSites,
      meta: {
        groupName: "YourSites"
      }
    },
    {
      path: '/missingestimates/:date',
      name: 'MissingEstimates',
      component: MissingEstimate,
      meta: {
        groupName: "YourSites"
      }
    },
    {
      path: '/missingkwh/:date',
      name: 'MissingKwh',
      component: MissingKwh,
      meta: {
        groupName: "YourSites"
      }
    },
    {
      path: '/sites/:id/inverters_list',
      name: 'Inverters',
      component: Inverter,
      meta: {
        groupName: "YourSites"
      }
    },
  ],
};

let casePage = {
  path: "/",
  component: DashboardLayout,
  name: "Cases",
  children: [
    {
      path: "/cases/open-cases",
      name: "OpenCases",
      component: OpenCases,
      meta: {
        groupName: "Cases",
      },
    },
    {
      path: "/cases/closed-cases",
      component: ClosedCases,
      name: "ClosedCases",
      meta: {
        groupName: "Cases",
      },
    },
    {
      path: '/cases/:id',
      name: 'Case',
      component: Case,
      meta: {
        groupName: "Cases",
      },
    },
  ],
};

let profilesPage = {
  path: "/",
  component: DashboardLayout,
  name: "Profiles",
  children: [
    {
      path: "/profiles/users",
      name: "Users",
      component: UserManagement,
      meta: {
        groupName: "Profiles"
      }
    },
    {
      path: "/profiles/customers",
      name: "Customers",
      component: Customer,
      meta: {
        groupName: "Profiles"
      }
    },
    {
      path: "/profiles/accounts",
      name: "Accounts",
      component: Account,
      meta: {
        groupName: "Profiles"
      }
    },
    {
      path: "/profiles/contacts",
      name: "Contacts",
      component: Contact,
      meta: {
        groupName: "Profiles"
      }
    },
  ],
};

let adminPage = {
  path: "/",
  component: DashboardLayout,
  name: "Admin",
  children: [
    {
      path: "/admin/api-management",
      name: "APIManagement",
      component: APIManagement,
      meta: {
        groupName: "Admin"
      }
    },
    {
      path: "/admin/data-management",
      name: "DataManagement",
      component: DataManagement,
      meta: {
        groupName: "Admin"
      }
    },
    {
      path: "/admin/site-management",
      name: "SiteManagement",
      component: SiteManagement,
      meta: {
        groupName: "Admin"
      }
    },
    {
      path: "/admin/import-issues",
      name: "ImportIssues",
      component: ImportIssue,
      meta: {
        groupName: "Admin"
      }
    },
    {
      path: "/admin/error-logs",
      name: "ErrorLogs",
      component: AdminLog,
      meta: {
        groupName: "Admin"
      }
    },
    {
      path: "/admin/app-settings",
      name: "AppSettings",
      component: AdminSetting,
      meta: {
        groupName: "Admin"
      }
    },
    {
      path: "/admin/csv-upload",
      name: "CSVUpload",
      component: CSVUpload,
      meta: {
        groupName: "Admin"
      }
    },
  ],
};

const routes = [
  {
    path: "/",
    name: "Dashboard",
    redirect: "/pages/dashboards/monitoring-portal",
    component: DashboardLayout,
    children: [
      {
        path: "profile/my-profile",
        name: "MyProfile",
        component: Profile,
        meta: {
          groupName: "Profile"
        }
      },
      // {
      //   path: "site-metrics",
      //   component: NewSiteMetrics,
      // },
      {
        path: "pages/dashboards/monitoring-portal",
        name: "Monitoring Portal",
        component: Dashboard,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "pages/dashboards/analytics",
        name: "Analytics",
        component: Analytics,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "pages/dashboards/discover",
        name: "Discover",
        component: Discover,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/smart-home",
        name: "SmartHome",
        component: SmartHome,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/crm",
        name: "Crm",
        component: Crm,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/dashboards/automotive",
        name: "Automotive",
        component: Automotive,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/sales",
        name: "Sales",
        component: Sales,
        meta: {
          groupName: "Dashboards",
        },
      },
    ],
  },
  {
    path: "/test",
    name: "Test Mention",
    component: TestMention,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUpCover,
  },
  {
    path: "/confirm",
    component: ConfirmEmail, // This is the component you will create
    name: "confirm",
  },
  {
    path: "/single-customer",
    name: "SingleCustomer",
    component: SingleCustomer,
  },
  {
    path: "/fleet-view",
    name: "FleetView",
    component: FleetView,
  },
  // {
  //   path: "/quarter-chart",
  //   name: "QuarterChart",
  //   component: QuarterChart,
  // },
  // {
  //   path: "/sign-up",
  //   component: SignUpCover
  // },
  // {
  //   path: "/sign-in",
  //   component: SignIn
  // },
  adminPage,
  casePage,
  sitePage,
  reportPage,
  // pricingPage,
  profilesPage,
  // applicationPages,
  // userPages,
  // accountPages,
  // projectsPages,
  // vrPages,
  // authBasicPages,
  signInPage,
  // authIllustrationPages,
];

const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.path == '/logout') {
    store.dispatch("logout").then(() => {
      window.location.href = "/sign-in";
    });
  } else if (to.path == '/sign-up' || to.path === '/confirm') {
    next();
  } else if (to.path !== '/sign-in' && store.state.auth.status !== 'success') {
    if (
      (to.path == '/single-customer') || (to.path == '/fleet-view')) {
      next();
    } else {
      next('/sign-in');
    }
  } else {
    // check restricted routes
    if(to.path.indexOf("admin") != -1) {
      // check role
      console.log(`checking restricted path: ${to.path}`);
    console.log(`checking role for user: ${store.state.auth.currentUser.email}`);
      if(store.state.auth.currentUser.claims.user_role == "super_user") {
        next();
      } else {
        console.log(`role: ${store.state.auth.currentUser.claims.user_role}`)
        next('/unauthorized');
      }
    }
    next();
  }
});
export default router;
