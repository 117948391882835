var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "my-4 ms-4 border-radius-xl",
      class: !_vm.$vuetify.breakpoint.mobile ? "" : "bg-white",
      attrs: {
        width: "100%",
        height: "calc(100% - 2rem)",
        fixed: "",
        app: "",
        floating: "",
        "expand-on-hover": _vm.mini,
        value: _vm.drawer,
        right: _vm.$vuetify.rtl,
        "data-color": _vm.sidebarColor,
        "data-theme": _vm.sidebarTheme,
      },
    },
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                { staticClass: "title d-flex align-center mb-0" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "v-navigation-drawer-brand d-flex align-center mx-auto",
                    },
                    [
                      _c("v-img", {
                        staticClass: "navbar-brand-img ms-3",
                        attrs: {
                          src: require("@/assets/img/logoSOV.png"),
                          height: "50px",
                          width: "120px",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr", {
        staticClass: "horizontal mb-0",
        class: _vm.sidebarTheme == "dark" ? "light" : "dark",
      }),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            "v-list-group",
            {
              staticClass: "pb-1 mx-2",
              attrs: {
                ripple: false,
                "append-icon": "fas fa-angle-down",
                "active-class": "item-active",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function () {
                    return [
                      _c(
                        "v-avatar",
                        { staticClass: "my-3 ms-2", attrs: { size: "30" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-h6 text-typo font-weight-light",
                            },
                            [_vm._v(_vm._s(_vm.initials))]
                          ),
                        ]
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "ms-2 ps-1 font-weight-light" },
                            [_vm._v(" " + _vm._s(_vm.currentName) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            _vm._l(_vm.userInfo, function (child) {
              return _c(
                "v-list-item",
                {
                  key: child.title,
                  staticClass: "mb-1 no-default-hover py-2",
                  attrs: { ripple: false, link: "", to: child.link },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-100 d-flex align-center pa-2 border-radius-lg",
                    },
                    [
                      _c("span", {
                        staticClass:
                          "v-list-item-mini ms-0 font-weight-light text-center w-20",
                        domProps: { textContent: _vm._s(child.prefix) },
                      }),
                      !child.items
                        ? _c(
                            "v-list-item-content",
                            { staticClass: "ms-2 ps-1" },
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(child.title) },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      child.items
                        ? _c(
                            "v-list-item-content",
                            { staticClass: "ms-1 ps-1 py-0" },
                            [
                              _c(
                                "v-list-group",
                                {
                                  attrs: {
                                    "prepend-icon": "",
                                    ripple: false,
                                    "sub-group": "",
                                    "no-action": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-list",
                                              {
                                                staticClass: "pa-0",
                                                attrs: { nav: "", dense: "" },
                                              },
                                              [
                                                _c("v-list-group", {
                                                  staticClass: "mb-0",
                                                  attrs: {
                                                    ripple: false,
                                                    "append-icon":
                                                      "fas fa-angle-down me-auto ms-1",
                                                    "active-class":
                                                      "item-active",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "py-0",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          child.title
                                                                        ),
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: child.active,
                                    callback: function ($$v) {
                                      _vm.$set(child, "active", $$v)
                                    },
                                    expression: "child.active",
                                  },
                                },
                                _vm._l(child.items, function (child2) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: child2.title,
                                      attrs: { ripple: false, to: child2.link },
                                      on: {
                                        click: function ($event) {
                                          return _vm.listClose($event)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "v-list-item-mini",
                                        domProps: {
                                          textContent: _vm._s(child2.prefix),
                                        },
                                      }),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              textContent: _vm._s(child2.title),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("hr", {
        staticClass: "horizontal mb-3",
        class: _vm.sidebarTheme == "dark" ? "light" : "dark",
      }),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _vm._l(_vm.items, function (item) {
            return _c(
              "v-list-group",
              {
                key: item.title,
                staticClass: "pb-1 mx-2",
                attrs: {
                  ripple: false,
                  "append-icon": "fas fa-angle-down",
                  "active-class": "item-active",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "me-2 align-center" },
                            [
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons-round opacity-10",
                                },
                                [_vm._v(_vm._s(item.action))]
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                staticClass: "ms-1",
                                domProps: { textContent: _vm._s(item.title) },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: item.active,
                  callback: function ($$v) {
                    _vm.$set(item, "active", $$v)
                  },
                  expression: "item.active",
                },
              },
              _vm._l(item.items, function (child) {
                return _c(
                  "v-list-item",
                  {
                    key: child.title,
                    staticClass: "mb-1 no-default-hover px-0",
                    class: child.items ? "has-children" : "",
                    attrs: { ripple: false, link: "", to: child.link },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-100 d-flex align-center pa-2 border-radius-lg",
                      },
                      [
                        _c("span", {
                          staticClass: "v-list-item-mini",
                          domProps: { textContent: _vm._s(child.prefix) },
                        }),
                        !child.items
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "ms-6 ps-1" },
                              [
                                _c("v-list-item-title", {
                                  domProps: {
                                    textContent: _vm._s(child.title),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.listClose($event)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        child.items
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "ms-6 ps-1 py-0" },
                              [
                                _c(
                                  "v-list-group",
                                  {
                                    attrs: {
                                      "prepend-icon": "",
                                      ripple: false,
                                      "sub-group": "",
                                      "no-action": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-list",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { nav: "", dense: "" },
                                                },
                                                [
                                                  _c("v-list-group", {
                                                    staticClass: "mb-0",
                                                    attrs: {
                                                      ripple: false,
                                                      "append-icon":
                                                        "fas fa-angle-down me-auto ms-1",
                                                      "active-class":
                                                        "item-active",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "py-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              child.title
                                                                            ),
                                                                        },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: child.active,
                                      callback: function ($$v) {
                                        _vm.$set(child, "active", $$v)
                                      },
                                      expression: "child.active",
                                    },
                                  },
                                  _vm._l(child.items, function (child2) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: child2.title,
                                        attrs: {
                                          ripple: false,
                                          to: child2.link,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.listClose($event)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "v-list-item-mini",
                                          domProps: {
                                            textContent: _vm._s(child2.prefix),
                                          },
                                        }),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  child2.title
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              1
            )
          }),
          _c(
            "v-list-item-group",
            _vm._l(_vm.itemSites, function (item) {
              return _c(
                "div",
                { key: item.title },
                [
                  !item.external
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "mb-2 mx-2 no-default-hover py-0",
                          attrs: {
                            link: "",
                            to: item.link,
                            ripple: false,
                            "active-class": "item-active",
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "me-2 align-center" },
                            [
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons-round opacity-10",
                                },
                                [_vm._v(_vm._s(item.action))]
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                staticClass: "ms-1",
                                domProps: { textContent: _vm._s(item.title) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.external
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "pb-1 mx-2 no-default-hover py-2",
                          attrs: {
                            link: "",
                            href: item.link,
                            ripple: false,
                            "active-class": "item-active",
                            target: "_blank",
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "me-2 align-center" },
                            [
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons-round opacity-10",
                                },
                                [_vm._v(_vm._s(item.action))]
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                staticClass: "ms-1",
                                domProps: { textContent: _vm._s(item.title) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
          _vm._l(_vm.filterDropdowns, function (item) {
            return _c(
              "v-list-group",
              {
                key: item.title,
                staticClass: "pb-1 mx-2",
                attrs: {
                  ripple: false,
                  "append-icon": "fas fa-angle-down",
                  "active-class": "item-active",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "me-2 align-center" },
                            [
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons-round opacity-10",
                                },
                                [_vm._v(_vm._s(item.action))]
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                staticClass: "ms-1",
                                domProps: { textContent: _vm._s(item.title) },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: item.active,
                  callback: function ($$v) {
                    _vm.$set(item, "active", $$v)
                  },
                  expression: "item.active",
                },
              },
              _vm._l(item.items, function (child) {
                return _c(
                  "v-list-item",
                  {
                    key: child.title,
                    staticClass: "mb-1 no-default-hover px-0",
                    class: child.items ? "has-children" : "",
                    attrs: { ripple: false, link: "", to: child.link },
                  },
                  [
                    !child.items
                      ? _c("v-list-item-content", { staticClass: "ps-4" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center pa-2" },
                            [
                              _c("span", {
                                staticClass: "v-list-item-mini ms-0",
                                domProps: { textContent: _vm._s(child.prefix) },
                              }),
                              _c("v-list-item-title", {
                                staticClass: "ms-6",
                                domProps: { textContent: _vm._s(child.title) },
                                on: {
                                  click: function ($event) {
                                    return _vm.listClose($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    child.items
                      ? _c(
                          "v-list-item-content",
                          { staticClass: "py-0" },
                          [
                            _c(
                              "v-list-group",
                              {
                                attrs: {
                                  "prepend-icon": "",
                                  ripple: false,
                                  "sub-group": "",
                                  "no-action": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-list",
                                            {
                                              staticClass: "py-2 ps-5 pe-2",
                                              attrs: { nav: "", dense: "" },
                                            },
                                            [
                                              _c("v-list-group", {
                                                staticClass: "mb-0",
                                                attrs: {
                                                  ripple: false,
                                                  "append-icon":
                                                    "fas fa-angle-down me-auto ms-1",
                                                  "active-class": "item-active",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "w-100 d-flex align-center",
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "v-list-item-mini ms-1",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      child.prefix
                                                                    ),
                                                                },
                                                              }),
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "py-0 ms-4",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "ms-2",
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              child.title
                                                                            ),
                                                                        },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: child.active,
                                  callback: function ($$v) {
                                    _vm.$set(child, "active", $$v)
                                  },
                                  expression: "child.active",
                                },
                              },
                              _vm._l(child.items, function (child2) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: child2.title,
                                    staticClass: "px-0",
                                    attrs: { ripple: false, to: child2.link },
                                    on: {
                                      click: function ($event) {
                                        return _vm.listClose($event)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-content", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center pa-2",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "v-list-item-mini",
                                            domProps: {
                                              textContent: _vm._s(
                                                child2.prefix
                                              ),
                                            },
                                          }),
                                          _c("v-list-item-title", {
                                            staticClass: "ms-6",
                                            domProps: {
                                              textContent: _vm._s(child2.title),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              1
            )
          }),
        ],
        2
      ),
      _c(
        "v-card",
        {
          staticClass: "pa-0 border-radius-lg mt-7 mb-9 mx-4",
          style:
            "background-image: url(" +
            require("../assets/img/curved-images/white-curved.jpeg") +
            "); background-size: cover;",
        },
        [
          _c("span", {
            staticClass: "mask opacity-8 border-radius-lg",
            class: "bg-gradient-" + _vm.sidebarColor,
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }