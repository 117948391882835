var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass:
            "\n       mt-6\n       py-2\n       px-0\n       mx-6\n       border-radius-xl\n       toolbar-content-padding-y-none\n     ",
          class: _vm.navbarFixed
            ? "position-sticky blur shadow-blur top-1 z-index-sticky py-2"
            : "",
          attrs: { color: _vm.background, height: "auto", flat: "" },
        },
        [
          _c(
            "v-row",
            { staticClass: "py-1" },
            [
              _c(
                "v-col",
                { staticClass: "d-flex", attrs: { cols: "12", sm: "8" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-breadcrumbs",
                        { staticClass: "pb-0 pt-1 px-0" },
                        [
                          _c(
                            "v-breadcrumbs-item",
                            {
                              staticClass: "opacity-5 text-dark",
                              attrs: {
                                to: "/dashboard",
                                "active-class": "active-breadcrumb",
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    width: "12px",
                                    height: "12px",
                                    viewBox: "0 0 45 40",
                                    version: "1.1",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    "xmlns:xlink":
                                      "http://www.w3.org/1999/xlink",
                                  },
                                },
                                [
                                  _c("title", [_vm._v("shop")]),
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        stroke: "none",
                                        "stroke-width": "1",
                                        fill: "none",
                                        "fill-rule": "evenodd",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(-1716.000000, -439.000000)",
                                            fill: "#252f40",
                                            "fill-rule": "nonzero",
                                          },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(1716.000000, 291.000000)",
                                              },
                                            },
                                            [
                                              _c(
                                                "g",
                                                {
                                                  attrs: {
                                                    transform:
                                                      "translate(0.000000, 148.000000)",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z",
                                                    },
                                                  }),
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "v-breadcrumbs__divider opacity-5 px-2 text-muted",
                            },
                            [_vm._v(" / ")]
                          ),
                          _c(
                            "v-breadcrumbs-item",
                            { staticClass: "opacity-5 text-dark" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$route.meta.groupName) + " "
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "v-breadcrumbs__divider opacity-5 px-2 text-muted",
                            },
                            [_vm._v(" / ")]
                          ),
                          _c(
                            "v-breadcrumbs-item",
                            {
                              staticClass: "no-default-hover text-dark",
                              attrs: { "active-class": "active-breadcrumb" },
                            },
                            [
                              _vm.$route.name === "Dashboard"
                                ? [_vm._v("Default")]
                                : [_vm._v(_vm._s(_vm.$route.name))],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "h6",
                        {
                          staticClass:
                            "text-h6 font-weight-bolder text-typo mb-0",
                        },
                        [_vm._v(" " + _vm._s(_vm.$route.name) + " ")]
                      ),
                    ],
                    1
                  ),
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "drawer-toggler pa-5 ms-6 cursor-pointer",
                          class: { active: _vm.togglerActive },
                          on: { click: _vm.minifyDrawer },
                        },
                        [
                          _c("div", { staticClass: "drawer-toggler-inner" }, [
                            _c("i", {
                              staticClass: "drawer-toggler-line bg-body",
                            }),
                            _c("i", {
                              staticClass: "drawer-toggler-line bg-body",
                            }),
                            _c("i", {
                              staticClass: "drawer-toggler-line bg-body",
                            }),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center",
                  class: _vm.$vuetify.rtl ? "text-sm-left" : "text-sm-right",
                  attrs: { cols: "12", sm: "4" },
                },
                [
                  _c("v-autocomplete", {
                    staticClass: "pl-16 pr-6",
                    attrs: {
                      items: _vm.searchList,
                      "auto-select-first": "",
                      "item-text": "name",
                      "hide-no-data": "",
                      dense: "",
                      outlined: "",
                      chips: "",
                      "background-color": "transparent",
                      label: "Search...",
                      placeholder: "Start typing to Search",
                      "hide-details": "",
                      color: "#e91e63",
                      "return-object": "",
                      id: "search_bar",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-list-item",
                              {
                                key: item.id,
                                on: {
                                  click: function ($event) {
                                    return _vm.goS(item.route, item.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.searchSite,
                      callback: function ($$v) {
                        _vm.searchSite = $$v
                      },
                      expression: "searchSite",
                    },
                  }),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "open-on-hover": "",
                        transition: "slide-y-transition",
                        "offset-y": "",
                        "offset-x": "",
                        "min-width": "300",
                        "max-width": "300",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "text-body",
                                      class: {
                                        "btn-dark-hover": !_vm.hasBg,
                                        "btn-hover": _vm.hasBg,
                                      },
                                      attrs: {
                                        icon: "",
                                        ripple: false,
                                        color: _vm.linkColor,
                                        small: "",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-icons-round",
                                      attrs: { size: "20" },
                                    },
                                    [_vm._v(" account_circle ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "pa-3" },
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass:
                                "\n                 pa-4\n                 list-item-hover-active\n                 d-flex\n                 align-items-center\n                 py-1\n                 my-1\n                 border-radius-md\n               ",
                              on: {
                                click: function ($event) {
                                  return _vm.go("/profile/my-profile")
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-icons-round text-body",
                                  attrs: { id: "main_prof", size: "20" },
                                },
                                [_vm._v("person")]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", [
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "\n                           text-sm\n                           font-weight-normal\n                           ms-2\n                           ma-auto\n                           text-typo\n                         ",
                                              },
                                              [_vm._v(" Profile ")]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass:
                                "\n                 pa-4\n                 list-item-hover-active\n                 d-flex\n                 align-items-center\n                 py-1\n                 my-1\n                 border-radius-md\n               ",
                              on: {
                                click: function ($event) {
                                  return _vm.logout()
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-icons-round text-body",
                                  attrs: { id: "logout", size: "20" },
                                },
                                [_vm._v("logout")]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", [
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "\n                           text-sm\n                           font-weight-normal\n                           ms-2\n                           ma-auto\n                           text-typo\n                         ",
                                              },
                                              [_vm._v(" Logout ")]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "v-btn",
                        {
                          staticClass:
                            "\n             font-weight-600\n             text-capitalize\n             drawer-toggler\n             py-3\n             px-0\n             rounded-sm\n           ",
                          class: {
                            "btn-dark-toggler-hover": !_vm.hasBg,
                            "btn-toggler-hover": _vm.hasBg,
                            active: _vm.togglerActive,
                          },
                          attrs: {
                            elevation: "0",
                            ripple: false,
                            height: "43",
                            color: "transparent",
                          },
                          on: { click: _vm.drawerClose },
                        },
                        [
                          _c("div", { staticClass: "drawer-toggler-inner" }, [
                            _c("i", {
                              staticClass: "drawer-toggler-line text-body",
                            }),
                            _c("i", {
                              staticClass: "drawer-toggler-line text-body",
                            }),
                            _c("i", {
                              staticClass: "drawer-toggler-line text-body",
                            }),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.user.claims.user_role == "super_user",
                          expression: "user.claims.user_role == 'super_user'",
                        },
                      ],
                      staticClass: "text-body px-5",
                      class: {
                        "btn-dark-hover": !_vm.hasBg,
                        "btn-hover": _vm.hasBg,
                      },
                      attrs: {
                        icon: "",
                        ripple: false,
                        color: _vm.linkColor,
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.go("/admin/api-management")
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-icons-round",
                          attrs: { size: "20" },
                        },
                        [_vm._v(" settings ")]
                      ),
                    ],
                    1
                  ),
                  _vm.notificationFeatureReady
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-body",
                          class: {
                            "btn-dark-hover": !_vm.hasBg,
                            "btn-hover": _vm.hasBg,
                          },
                          attrs: {
                            icon: "",
                            ripple: false,
                            color: _vm.linkColor,
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showAlertModal = true
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "material-icons-round",
                              attrs: { size: "20" },
                            },
                            [_vm._v(" notifications ")]
                          ),
                          _c("v-badge", {
                            staticClass: "position-absolute",
                            attrs: {
                              color: "#f44335",
                              content: _vm.alertCount,
                              value: _vm.alertCount,
                              "offset-x": "1",
                              "offset-y": "0",
                              bordered: "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          model: {
            value: _vm.showAlertModal,
            callback: function ($$v) {
              _vm.showAlertModal = $$v
            },
            expression: "showAlertModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Alerts ")]),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: { headers: _vm.alert_headers, items: _vm.alertData },
                scopedSlots: _vm._u([
                  {
                    key: "item.msg",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "a",
                          {
                            attrs: { href: item.url },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.openWindow(item.url)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.msg) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.alert_actions",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-default bg-gradient-danger",
                            attrs: {
                              disabled:
                                _vm.user.claims.user_role == "customer_edit" ||
                                _vm.user.claims.user_role == "customer_view",
                              icon: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clearAlert(item.id)
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-delete")])],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bold text-xs btn-default bg-gradient-danger ml-2",
                      attrs: { ripple: false, elevation: 0, color: "error" },
                      on: { click: _vm.closeAlertModal },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }