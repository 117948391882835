var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        { staticClass: "auth-pages" },
        [
          _c(
            "div",
            {
              staticClass:
                "\n        header-auth\n        position-relative\n        ma-4\n        pb-16\n        pt-16\n        border-radius-xl\n        min-height-300\n      ",
              style:
                "background-image:  url('https://images.unsplash.com/photo-1509391366360-2e959784a276?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2072&q=80'); background-size: cover; background-position: center;",
            },
            [
              _c("span", {
                staticClass:
                  "mask bg-gradient-default border-radius-xl opacity-4",
              }),
              this.$route.name == "Pricing"
                ? _c("v-img", {
                    staticClass:
                      "position-absolute opacity-6 start-0 top-0 w-100",
                    attrs: {
                      src: require("@/assets/img/shapes/waves-white.svg"),
                      alt: "pattern-lines",
                    },
                  })
                : _vm._e(),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "d-flex mt-5" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mx-auto py-0 position-relative",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          this.$route.name == "Pricing"
                            ? _c(
                                "h3",
                                {
                                  staticClass:
                                    "text-h3 font-weight-bold text-white text-center mb-2",
                                },
                                [_vm._v(" " + _vm._s(_vm.headerTitle()) + " ")]
                              )
                            : _c(
                                "h1",
                                {
                                  staticClass:
                                    "\n                text-h1 text-white\n                font-weight-bolder\n                text-center\n                mb-2\n                mt-5\n              ",
                                },
                                [_vm._v(" " + _vm._s(_vm.headerTitle()) + " ")]
                              ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "\n                text-white\n                font-size-root\n                text-center\n                font-weight-thin\n                mb-12\n              ",
                            },
                            [_vm._v(" " + _vm._s(_vm.paragraphs) + " ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "fade-transition",
            { attrs: { duration: 200, origin: "center top", mode: "out-in" } },
            [
              _c(
                "v-container",
                { staticClass: "mt-n16 pb-0" },
                [
                  _c("router-view"),
                  !_vm.$route.meta.hideFooter
                    ? _c("content-footer", { attrs: { auth: "" } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }