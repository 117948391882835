var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c(
          "v-footer",
          { staticClass: "px-6", attrs: { color: "transparent" } },
          [
            _c(
              "v-card",
              { staticClass: "flex", attrs: { color: "transparent" } },
              [
                _c(
                  "v-card-text",
                  { staticClass: "px-0" },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "copyright text-body-2 ls-0 text-muted",
                            },
                            [
                              _vm._v(
                                " © " + _vm._s(new Date().getFullYear()) + ", "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "\n                    text-decoration-none text-typo text-body-2\n                    ls-0\n                    font-weight-bold\n                  ",
                                  attrs: {
                                    href: "https://app.sunovision.com",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Sunovision")]
                              ),
                              _vm._v(" All Rights Reserved "),
                            ]
                          ),
                        ]),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-end",
                            attrs: { cols: "10", md: "6" },
                          },
                          _vm._l(_vm.footer, function (item) {
                            return _c(
                              "ul",
                              {
                                key: item.linkName,
                                staticClass: "d-flex list-style-none",
                              },
                              [
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "\n                      text-decoration-none text-muted text-body-2\n                      ls-0\n                      btn-dark-hover\n                      no-default-hover\n                    ",
                                      attrs: {
                                        href: item.link,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.linkName))]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }