var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "position-relative py-0", attrs: { cols: "4" } },
            [
              _vm._l(_vm.pages1, function (item) {
                return _c(
                  "v-list",
                  { key: item.title, staticClass: "px-3" },
                  [
                    _c(
                      "v-subheader",
                      { staticClass: "d-flex align-center mb-0" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass:
                              "material-icons-round me-2 text-dark opacity-6",
                            attrs: { size: "16" },
                          },
                          [_vm._v(" " + _vm._s(item.icon) + " ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "text-dark font-weight-bolder text-sm",
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                    _vm._l(item.links, function (item2) {
                      return _c(
                        "v-list-item",
                        { key: item2.title, staticClass: "mb-0 ms-2 ps-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-body text-capitalize font-weight-light ls-0",
                              attrs: {
                                ripple: false,
                                text: "",
                                depressed: "",
                                link: "",
                                to: item2.link,
                              },
                            },
                            [
                              _c("span", { staticClass: "text-body" }, [
                                _vm._v(_vm._s(item2.title)),
                              ]),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
              _c("hr", { staticClass: "vertical dark" }),
            ],
            2
          ),
          _c(
            "v-col",
            { staticClass: "position-relative py-0", attrs: { cols: "4" } },
            [
              _vm._l(_vm.pages2, function (item) {
                return _c(
                  "v-list",
                  { key: item.title, staticClass: "px-3" },
                  [
                    _c(
                      "v-subheader",
                      { staticClass: "d-flex align-center mb-0" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass:
                              "material-icons-round me-2 text-dark opacity-6",
                            attrs: { size: "16" },
                          },
                          [_vm._v(" " + _vm._s(item.icon) + " ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "text-dark font-weight-bolder text-sm",
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                    _vm._l(item.links, function (item2) {
                      return _c(
                        "v-list-item",
                        { key: item2.title, staticClass: "mb-0 ms-2 ps-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-body text-capitalize font-weight-light ls-0",
                              attrs: {
                                ripple: false,
                                text: "",
                                depressed: "",
                                link: "",
                                to: item2.link,
                              },
                            },
                            [
                              _c("span", { staticClass: "text-body" }, [
                                _vm._v(_vm._s(item2.title)),
                              ]),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
              _c("hr", { staticClass: "vertical dark" }),
            ],
            2
          ),
          _c(
            "v-col",
            { staticClass: "position-relative py-0", attrs: { cols: "4" } },
            _vm._l(_vm.pages3, function (item) {
              return _c(
                "v-list",
                { key: item.title, staticClass: "px-3" },
                [
                  _c(
                    "v-subheader",
                    { staticClass: "d-flex align-center mb-0" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass:
                            "material-icons-round me-2 text-dark opacity-6",
                          attrs: { size: "16" },
                        },
                        [_vm._v(" " + _vm._s(item.icon) + " ")]
                      ),
                      _c(
                        "span",
                        { staticClass: "text-dark font-weight-bolder text-sm" },
                        [_vm._v(_vm._s(item.title))]
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _vm._l(item.links, function (item2) {
                    return _c(
                      "v-list-item",
                      { key: item2.title, staticClass: "mb-0 ms-2 ps-4" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass:
                              "text-body text-capitalize font-weight-light ls-0",
                            attrs: {
                              ripple: false,
                              text: "",
                              depressed: "",
                              link: "",
                              to: item2.link,
                            },
                          },
                          [
                            _c("span", { staticClass: "text-body" }, [
                              _vm._v(_vm._s(item2.title)),
                            ]),
                            _c("v-spacer"),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }