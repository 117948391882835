var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { staticClass: "py-0" },
    _vm._l(_vm.pages, function (item) {
      return _c(
        "v-list-item",
        { key: item.title, staticClass: "mb-0 ps-0" },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-dark font-weight-normal text-capitalize ls-0",
              attrs: {
                ripple: false,
                text: "",
                depressed: "",
                link: "",
                to: item.link,
              },
            },
            [_vm._v(" " + _vm._s(item.title) + " "), _c("v-spacer")],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }