var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("Confirming Your Email")]),
    _vm.loading
      ? _c("p", [_vm._v("Confirming your email, please wait...")])
      : _vm.error
      ? _c("p", [_vm._v(_vm._s(_vm.error))])
      : _c(
          "p",
          [
            _vm._v("Your email has been successfully confirmed. You can now "),
            _c("router-link", { attrs: { to: "/sign-in" } }, [
              _vm._v("sign in"),
            ]),
            _vm._v("."),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }