<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import '@progress/kendo-theme-material/dist/all.css';

export default {};
</script>
