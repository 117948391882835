import Service from "@/services/Service.js";

export default {
  state: {
    alerts: [],
    dailyChecks: [],
    test: 0,
    checks: [],
    userColumns: JSON.parse(localStorage.getItem("userColumns")) || [],
    caseColumns: JSON.parse(localStorage.getItem("caseColumns")) || [],
    highAlerts: JSON.parse(localStorage.getItem("highAlerts")) || [
      {
        highThresholdSites: 10,
        highThresholdInverters: 10,
        highThresholdInsite: 10,
      },
    ],
    mediumAlerts: JSON.parse(localStorage.getItem("mediumAlerts")) || [
      {
        mediumThresholdSites: 75,
        mediumThresholdInverters: 75,
        mediumThresholdInsite: 75,
      },
    ],
  },
  mutations: {
    UPDATE_CHECKS(state, payload) {
      state.checks = payload
    },
    UPDATE_USER_COLUMNS(state, payload) {
      state.userColumns = payload;
      localStorage.setItem("userColumns", JSON.stringify(payload));
    },
    UPDATE_CASE_COLUMNS(state, payload) {
      state.caseColumns = payload;
      localStorage.setItem("caseColumns", JSON.stringify(payload));
    },
    UPDATE_HIGH_ALERTS(state, payload) {
      state.highAlerts = payload;
      localStorage.setItem("highAlerts", JSON.stringify(payload));
    },
    UPDATE_MEDIUM_ALERTS(state, payload) {
      state.mediumAlerts = payload;
      localStorage.setItem("mediumAlerts", JSON.stringify(payload));
    },
    ADD_CHECK(state, payload) {
      state.checks = payload
    },
    setDailyChecks(state, checks) {
      state.dailyChecks = checks
    },
    addAlert(state, message) {
      // payload.received_at = new Date(payload.received_at*1000).toLocaleString()
      console.log(`in store addAlert: ${message}`)
      state.alerts.push(message)
    },
    removeAlert: (state, payload) => {
      // TODO: call to remove entry from AlertService
      Service.removeAlert(payload)
        .then(() => {
          console.log("item removed");
          const i = state.alerts.findIndex((item) => item.date === payload);
          state.alerts.splice(i, 1);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
  actions: {
    setUserColumns(context, payload) {
      context.commit("UPDATE_USER_COLUMNS", payload);
    },
    setCaseColumns(context, payload) {
      context.commit("UPDATE_CASE_COLUMNS", payload);
    },
    setHighAlerts(context, payload) {
      context.commit("UPDATE_HIGH_ALERTS", payload);
    },
    setMediumAlerts(context, payload) {
      context.commit("UPDATE_MEDIUM_ALERTS", payload);
    },
    setChecks(context, payload) {
      context.commit("UPDATE_CHECKS", payload);
    },
    addToChecks(context, payload) {
      const checks = context.state.checks
      checks.push(payload)
      context.commit("ADD_CHECK", checks);
    }
  },
  getters: {
    alertCount: (state) => {
      return state.alerts.length;
    },
    checks: function (state) {
      return state.checks;
    },
    dailyChecks: (state) => {
      return state.dailyChecks;
    },
    userColumns: (state) => {
      return state.userColumns;
    },
    caseColumns: (state) => {
      return state.caseColumns;
    },
    highAlerts: (state) => {
      return state.highAlerts;
    },
    mediumAlerts: (state) => {
      return state.mediumAlerts;
    },
    dailyChecksDate() {
      let found = this.$store.dailyChecks.filter((check) => check.date);
      return found;
    },
    allAlerts: (state) => {
      return state.alerts;
      // console.log(`allAlerts: length ${state.alerts.length}`)
      // let arrayCopy = [...state.alerts];
      // return arrayCopy;
    },
    foo: (state) => {
      return state.test;
    },
  },
};