<template>
  <div>
    <h1>Confirming Your Email</h1>
    <p v-if="loading">Confirming your email, please wait...</p>
    <p v-else-if="error">{{ error }}</p>
    <p v-else>Your email has been successfully confirmed. You can now <router-link to="/sign-in">sign in</router-link>.</p>
  </div>
</template>

<script>

import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";

Vue.use(VueToast);

export default {
  data() {
    return {
      loading: true,
      error: null,
    };
  },
  created() {
    debugger;
    const token = this.$route.query.token;
    if (token) {
      this.confirmEmail(token);
    } else {
      this.error = "Invalid confirmation link.";
      this.loading = false;
    }
  },
  methods: {
    confirmEmail(token) {
      Service.confirmEmail(token)
        .then((response) => {
          if (response.data.success) {
            this.loading = false;
          } else {
            this.error = "Failed to confirm email.";
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.response.data.error;
          // this.error = "An error occurred while confirming your email.";
          this.loading = false;
        });
      // try {
      //   const response = await this.$http.post('/api/confirm-email', { token }); // Adjust the endpoint as necessary
      //   if (response.data.success) {
      //     this.loading = false;
      //   } else {
      //     this.error = 'Failed to confirm email.';
      //     this.loading = false;
      //   }
      // } catch (error) {
      //   this.error = 'An error occurred while confirming your email.';
      //   this.loading = false;
      // }
    },
  },
};
</script>
