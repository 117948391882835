var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fixed-plugin",
      class: _vm.showSettingsDrawer == true ? "show" : "",
    },
    [
      _c("v-card", { staticClass: "shadow-lg" }, [
        _c("div", { staticClass: "card-padding mb-16" }, [
          _c("div", { staticClass: "float-start" }, [
            _c(
              "h5",
              { staticClass: "text-h5 text-dark font-weight-bold mt-3 mb-0" },
              [_vm._v(" Material UI Configurator ")]
            ),
            _c("p", { staticClass: "text-body font-weight-light" }, [
              _vm._v("See our dashboard options."),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "float-end mt-4" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-dark",
                  attrs: {
                    ripple: false,
                    icon: "",
                    rounded: "",
                    width: "20px",
                    height: "20px",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggleSettingsDrawer", false)
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-icons-round",
                      attrs: { size: "18" },
                    },
                    [_vm._v("clear")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("hr", { staticClass: "horizontal dark my-1" }),
        _c(
          "div",
          { staticClass: "card-padding" },
          [
            _c(
              "div",
              [
                _c(
                  "h6",
                  { staticClass: "text-h6 text-typo font-weight-bold mb-2" },
                  [_vm._v(" Sidebar Colors ")]
                ),
                _c(
                  "v-btn-toggle",
                  {
                    attrs: {
                      tile: "",
                      color: "accent-3 deep-purple",
                      group: "",
                      defaultValue: "primary",
                      rounded: "",
                    },
                    model: {
                      value: _vm.sidebarColorModel,
                      callback: function ($$v) {
                        _vm.sidebarColorModel = $$v
                      },
                      expression: "sidebarColorModel",
                    },
                  },
                  [
                    _c("v-btn", {
                      staticClass:
                        "px-0 mx-0 bg-gradient-primary rounded-circle border-0 me-2",
                      attrs: {
                        elevation: 0,
                        height: "23",
                        width: "23",
                        "min-width": "23",
                        small: "",
                        rounded: "",
                        ripple: false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("updateSidebarColor", "primary")
                        },
                      },
                    }),
                    _c("v-btn", {
                      staticClass:
                        "px-0 mx-0 bg-gradient-default rounded-circle border-0 me-2",
                      attrs: {
                        elevation: 0,
                        height: "23",
                        width: "23",
                        "min-width": "23",
                        small: "",
                        rounded: "",
                        ripple: false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("updateSidebarColor", "default")
                        },
                      },
                    }),
                    _c("v-btn", {
                      staticClass:
                        "px-0 mx-0 bg-gradient-info rounded-circle border-0 me-2",
                      attrs: {
                        elevation: 0,
                        height: "23",
                        width: "23",
                        "min-width": "23",
                        small: "",
                        rounded: "",
                        ripple: false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("updateSidebarColor", "info")
                        },
                      },
                    }),
                    _c("v-btn", {
                      staticClass:
                        "px-0 mx-0 bg-gradient-success rounded-circle border-0 me-2",
                      attrs: {
                        elevation: 0,
                        height: "23",
                        width: "23",
                        "min-width": "23",
                        small: "",
                        rounded: "",
                        ripple: false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("updateSidebarColor", "success")
                        },
                      },
                    }),
                    _c("v-btn", {
                      staticClass:
                        "px-0 mx-0 bg-gradient-warning rounded-circle border-0 me-2",
                      attrs: {
                        elevation: 0,
                        height: "23",
                        width: "23",
                        "min-width": "23",
                        small: "",
                        rounded: "",
                        ripple: false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("updateSidebarColor", "warning")
                        },
                      },
                    }),
                    _c("v-btn", {
                      staticClass:
                        "px-0 mx-0 bg-gradient-danger rounded-circle border-0 me-2",
                      attrs: {
                        elevation: 0,
                        height: "23",
                        width: "23",
                        "min-width": "23",
                        small: "",
                        rounded: "",
                        ripple: false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("updateSidebarColor", "danger")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "h6",
              { staticClass: "text-h6 text-typo font-weight-bold mb-0 mt-4" },
              [_vm._v(" Sidenav Type ")]
            ),
            _c("p", { staticClass: "text-sm text-body font-weight-light" }, [
              _vm._v(" Choose between 3 different sidenav types. "),
            ]),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "font-weight-bold text-uppercase btn-default py-2 px-6 me-2",
                    class:
                      _vm.sidebarTheme == "dark"
                        ? "bg-gradient-default"
                        : "btn-outline-default",
                    attrs: {
                      elevation: "0",
                      height: "43",
                      color: "#fff",
                      small: "",
                    },
                    on: {
                      click: function ($event) {
                        ;[_vm.$emit("updateSidebarTheme", "dark"), _vm.active()]
                      },
                    },
                  },
                  [_vm._v("Dark")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "font-weight-bold text-uppercase btn-default py-2 px-4 me-2",
                    class:
                      _vm.sidebarTheme == "transparent"
                        ? "bg-gradient-default"
                        : "btn-outline-default",
                    attrs: {
                      elevation: "0",
                      height: "43",
                      color: "#fff",
                      small: "",
                    },
                    on: {
                      click: function ($event) {
                        ;[
                          _vm.$emit("updateSidebarTheme", "transparent"),
                          _vm.active(),
                        ]
                      },
                    },
                  },
                  [_vm._v("Transparent")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "font-weight-bold text-uppercase btn-default py-2 px-4 me-2",
                    class:
                      _vm.sidebarTheme == "white"
                        ? "bg-gradient-default"
                        : "btn-outline-default",
                    attrs: {
                      elevation: "0",
                      height: "43",
                      color: "#fff",
                      small: "",
                    },
                    on: {
                      click: function ($event) {
                        ;[
                          _vm.$emit("updateSidebarTheme", "white"),
                          _vm.active(),
                        ]
                      },
                    },
                  },
                  [_vm._v("White")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "text-sm d-lg-none d-block mt-3 text-body" },
              [
                _vm._v(
                  " You can change the sidenav type just on desktop view. "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "mt-7 d-flex align-center justify-between" },
              [
                _c(
                  "h6",
                  { staticClass: "text-h6 text-typo font-weight-bold mb-0" },
                  [_vm._v("Navbar Fixed")]
                ),
                _c("v-switch", {
                  staticClass: "mt-0 ms-auto pt-0 switch",
                  attrs: { "hide-details": "" },
                  on: {
                    change: function ($event) {
                      return _vm.$emit(
                        "toggleNavbarPosition",
                        _vm.navbarFixedModel
                      )
                    },
                  },
                  model: {
                    value: _vm.navbarFixedModel,
                    callback: function ($$v) {
                      _vm.navbarFixedModel = $$v
                    },
                    expression: "navbarFixedModel",
                  },
                }),
              ],
              1
            ),
            _c("hr", { staticClass: "horizontal dark mb-3 mt-4" }),
            _c(
              "v-btn",
              {
                staticClass:
                  "\n          font-weight-bold\n          text-uppercase\n          btn-info\n          bg-gradient-primary\n          py-2\n          px-6\n          me-2\n          w-100\n          mt-2\n          text-xs\n        ",
                attrs: {
                  elevation: "0",
                  height: "43",
                  href: "https://www.creative-tim.com/product/vuetify-material-dashboard-pro",
                  color: "#fff",
                },
              },
              [_vm._v("Buy Now")]
            ),
            _c(
              "v-btn",
              {
                staticClass:
                  "\n          font-weight-bold\n          text-uppercase\n          btn-default btn-outline-default\n          py-2\n          px-6\n          me-2\n          w-100\n          mt-4\n          text-xs\n          shadow-none\n        ",
                attrs: {
                  elevation: "0",
                  height: "43",
                  color: "#fff",
                  href: "https://vuetifyjs.com/en/components/alerts/",
                  target: "_blank",
                },
              },
              [_vm._v("View documentation")]
            ),
            _c(
              "div",
              { staticClass: "text-center d-flex mt-5" },
              [
                _c("gh-btns-star", {
                  staticClass: "mx-auto",
                  attrs: {
                    slug: "creativetimofficial/ct-vuetify-material-dashboard-pro",
                    "show-count": "",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "text-center mt-6" }, [
              _c(
                "h6",
                { staticClass: "text-h6 text-typo font-weight-bold mt-9 mb-2" },
                [_vm._v(" Thank you for sharing! ")]
              ),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "\n              font-weight-bold\n              text-uppercase\n              btn-default\n              py-2\n              px-6\n              mt-2\n              text-xs\n              me-2\n              ms-auto\n            ",
                      attrs: {
                        elevation: "0",
                        height: "43",
                        href: "https://twitter.com/intent/tweet?text=Check%20Vuetify%20Material%20Dashboard%202%20PRO%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5%20%23vuetify%20https%3A//www.creative-tim.com/product/vuetify-material-dashboard-pro",
                        color: "#344767",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "12" } }, [
                        _vm._v("fab fa-twitter me-2"),
                      ]),
                      _vm._v(" Tweet "),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "\n              font-weight-bold\n              text-uppercase\n              btn-default\n              py-2\n              px-6\n              me-2 me-auto\n              mt-2\n              text-xs\n            ",
                      attrs: {
                        elevation: "0",
                        height: "43",
                        href: "https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/vuetify-material-dashboard-pro",
                        color: "#344767",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "12" } }, [
                        _vm._v("fab fa-facebook me-2"),
                      ]),
                      _vm._v(" Share "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }